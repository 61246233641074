import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Container from '../UI/Container/Container';
import SearchResult from '../UI/SearchResult/SearchResult';
import { FaSearch } from 'react-icons/fa';

import useSanitySearch from '../../hooks/use-search';

import styles from './Search.module.css';

const Posts = () => {
    const [searchParam] = useSearchParams();

    const value = searchParam.get("q") || searchParam.get("tag");
    const [searchTerm, setSearchTerm] = useState('');

    if (value && value.length > 0) {
        var query = `*[_type == "newPost" &&  (content[].children[].text match "*${value}*" || title match "*${value}*" || "${value}" in tags[].value) || _type == "project" && (content[].children[].text match "*${value}*" || title match "*${value}*" || "${value}" in tags[].value)]`;
    }

    const posts = useSanitySearch(query);

    const navigate = useNavigate();
    useEffect(() => {
        setSearchTerm(value || "");
    }, [value]);

    const handleChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        navigate(`/search?q=${searchTerm}`);
    };


    return (
        <section className={styles.search}>
            <Container className={styles.searchCont}>
                <form className={styles.form} onSubmit={handleSubmit}>
                    <input
                        type="text"
                        value={searchTerm}
                        placeholder="Search..."
                        onChange={handleChange}
                    />
                    <button type="submit"><FaSearch /></button>
                </form>
                {posts && posts.length === 0 && <h2 className={styles.resultsTitle}>No results found for <span>{searchTerm}</span></h2>}
                {posts && posts.length > 0 && <h2 className={styles.resultsTitle}>{posts.length} result{posts.length > 1 && "s"} found for <span>{searchTerm}</span></h2>}
                {posts && <ul className={styles.results}>{posts.map((post) => <SearchResult key={post._id} {...post} />
                )}</ul>}
            </Container>
        </section >
    );
};

export default Posts;
