import { useState, useEffect } from 'react';
import { createClient } from '@sanity/client';

const client = createClient({
    projectId: '8xmbrpb7',
    dataset: 'prod',
    apiVersion: '2022-03-07',
    useCdn: true
});
const useSanitySearch = (query) => {
    const [results, setResults] = useState([]);
    useEffect(() => {
        client
            .fetch(query)
            .then((data) => {
                setResults(data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [query]);

    return results;
};

export default useSanitySearch;
