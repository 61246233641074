import { createSlice } from "@reduxjs/toolkit";
import { createClient } from '@sanity/client';

const client = createClient({
    projectId: '8xmbrpb7',
    dataset: 'prod',
    apiVersion: '2022-03-07',
    useCdn: true // use the CDN for faster queries
});


const WebSiteSlice = createSlice({
    name: "WebSite",
    initialState: {
        title: "",
        description: "",
        logo: "",
        favIcon: "",
    },
    reducers: {
        fetchWebSiteData(state, action) {
            state.title = action.payload[0].title;
            state.description = action.payload[0].description;
            state.logo = action.payload[0].logo;
            state.favIcon = action.payload[0].favicon || action.payload[0].logo;
        }
    }
});

export const getWebSiteData = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            return client.fetch(`
      *[_type == "websiteData"]{
      title,
      description,
      "logo":logo.asset->url,
      "favicon":favicon.asset->url,
    }| order(_createdAt desc)
    `).then(res => {
                return res;
            });
        };
        try {
            const data = await fetchData();
            dispatch(webSiteAction.fetchWebSiteData(data));
        } catch (error) {
            console.log(error);
        }
    };
};


export const webSiteAction = WebSiteSlice.actions;

export default WebSiteSlice.reducer;