
import ContactLink from './ContactLink';
import { FaEnvelope, FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from 'react-icons/fa';

import styles from './ContactForm.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getSocialData } from '../../../Store/Slices/SocialSlice';

const ContactForm = () => {


    const { facebook,
        instagram,
        x,
        youtube,
        email, } = useSelector(state => state.Social);

    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(getSocialData());
    }, [dispatch]);


    return (
        <div className={styles["f-contact"]}>
            <form>
                <h2>Get in touch with us</h2>
                <div className={styles["cust-info"]}>
                    <input type="text" placeholder="Name" />
                    <input type="email" placeholder="Email" />
                </div>
                <textarea placeholder="Message"></textarea>
                <button type="submit" className={styles["submit"]}>
                    Send
                </button>
            </form>
            <ul>
                <ContactLink title={email} link={`mailto:${email}`}>
                    <FaEnvelope />
                </ContactLink>
                <ContactLink title="Facebook" link={facebook}>
                    <FaFacebookF />
                </ContactLink>
                <ContactLink title="Twitter" link={x}>
                    <FaTwitter />
                </ContactLink>
                <ContactLink title="Instagram" link={instagram}>
                    <FaInstagram />
                </ContactLink>
                <ContactLink title="YouTube" link={youtube}>
                    <FaYoutube />
                </ContactLink>
            </ul>
        </div>
    );
};

export default ContactForm;
