
import { Link } from 'react-router-dom';
import Container from '../../UI/Container/Container';
import styles from './Hero.module.css';

const Hero = ({ title, link, coverImg, intro, centered }) => {
  return (
    <section className={styles['hero']} style={{ "--cover": `url(${coverImg})` }}>
      <Container className={styles["hero-cont"]}>
        <div className={`${styles["hc-c"]} ${centered && styles.centered}`}>
          <h1>{title}</h1>
          {intro && <p>{intro}</p>}
          {link && <Link to={link}>Learn More</Link>}
        </div>
      </Container>
    </section >
  );
};

export default Hero;
