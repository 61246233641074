import { useEffect } from "react";
import Title from "../../UI/Title/Title";
import TeamMember from "./TeamMember";
import Scroller from "../Scroller/Scroller";
import styles from "./Team.module.css";

import useFetch from "../../../hooks/use-fetch";

const TeamSection = () => {
  const { data, error, isloading, sendRequest } = useFetch();

  useEffect(() => {
    sendRequest(`*[_type == "teamMembers"]{
      name,
      position,
      "imageSrc":image.asset->url,
      _id
    }| order(_createdAt desc)`);
  }, [sendRequest]);

  const showCont = !isloading && !error && data.length > 0;

  let content = null;

  if (showCont) {
    content = data.map((member) => (
      <TeamMember key={member._id} {...member} />
    ));
  }

  return (
    <section className={styles["team-member"]}>
      <div className={styles["overlay"]}>

        <Title white="true">team members</Title>
        {content && (
          <Scroller>
            {content}
          </Scroller>
        )}

      </div>
    </section>
  );
};

export default TeamSection;
