import React from "react";
import styles from "./Team.module.css"; // Replace 'YourComponent' with your actual component name

const TeamMember = ({ imageSrc, name, position }) => {
  return (
    <div className={styles.card}>
      <img src={imageSrc} alt={name} />
      <div className={styles["tm-info"]}>
        <h4>{name}</h4>
        <span>{position}</span>
      </div>
    </div>
  );
};

export default TeamMember;
