import { FaSearch } from 'react-icons/fa';
import classes from './Nav.module.css';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';


const SearchBar = ({ showSearch, onClick }) => {

    const [value, setValue] = useState("");

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        // navigate(`/search/${value}`);
        navigate(`/search?q=${value}`);
    };

    return (<div className={classes.SearchBar}>
        <form className={`${classes["search-input"]} ${showSearch ? classes.active : ""}`} onSubmit={handleSubmit}>
            <button className={classes["search-btn"]} type='submit'>Go</button>
            <input type="search" placeholder="search..." className={`${classes["search"]} `} value={value} onChange={handleChange} />
            {/* <button><Link to={`/search/${value}`}>Search</Link></button> */}
        </form>
        <button className={classes["search-btn"]} onClick={onClick}>
            <FaSearch />
        </button>
    </div>);
};

export default SearchBar;