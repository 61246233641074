

const ContactLink = ({ title, link, children }) => {
    return (
        <li>
            <a href={link}>
                {children && children}
                <h3>{title}</h3>
            </a>
        </li>
    );
};

export default ContactLink;
