// ArticlesSection.jsx

import Title from '../UI/Title/Title';
import styles from './Footer.module.css';

import SingleArticle from './SingleArticle';

import postImg from '../../assets/Images/post.jpg';

const ArticlesSection = () => {
    return (
        <div className={`${styles['bf-sec']} ${styles['bf-art']}`}>
            <Title className={styles.title}>Latest Articles</Title>
            <ul>
                <SingleArticle
                    imageUrl={postImg}
                    date="15 AUG 2023"
                    title="Put your title here"
                    link="/article/1"
                />
                <SingleArticle
                    imageUrl={postImg}
                    date="16 AUG 2023"
                    title="Another article title"
                    link="/article/2"
                />
            </ul>
        </div>
    );
};

export default ArticlesSection;
