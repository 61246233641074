import { useDispatch, useSelector } from 'react-redux';
import Title from '../UI/Title/Title';
import styles from './Footer.module.css';

import { FaEnvelope, FaMapMarker } from 'react-icons/fa';
import { FaPhone } from "react-icons/fa6";
import { getSocialData } from '../../Store/Slices/SocialSlice';
import { useEffect } from 'react';

const ContactSection = () => {


    const {
        mobileNumber,
        email,
        address, } = useSelector(state => state.Social);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSocialData());
    }, [dispatch]);


    return (
        <div className={`${styles['bf-sec']} ${styles['bf-con']}`}>
            <Title className={styles.title}>Contact Us</Title>
            <ul>
                {mobileNumber && <li><FaPhone /><a href={`tel:${mobileNumber}`}>{mobileNumber}</a></li>}
                {email && <li><FaEnvelope /><a href={`mailto:${email}`}>{email}</a></li>}
                {address && <li><FaMapMarker />{address}</li>}
            </ul>
        </div>
    );
};

export default ContactSection;
