import { useEffect } from "react";

import useFetch from "../../hooks/use-fetch";

import Hero from "../UI/Hero/Hero";
import Container from "../UI/Container/Container";
import Map from "../ContactPage/Map/Map";
import ContactForm from "../ContactPage/ContactForm/ContactForm";

import styles from "./ContactPage.module.css";


const ContactPage = () => {
    const { data, error, isloading, sendRequest } = useFetch();

    useEffect(() => {
        sendRequest(`*[_type == "homePage"]{
            title,
            "heroImage":heroImage.asset->url,
            description
          }|order(_createdAt desc)`);
    }, [sendRequest]);

    const showCont = !isloading && !error && data.length > 0;

    let title, coverImg, description;
    if (showCont) {
        title = data[0].title;
        coverImg = data[0].heroImage;
        description = data[0].description;
    }

    return (<>
        <Hero title={title} coverImg={coverImg} intro={description} />
        <Container className={styles.contactCont}>
            <Map />
            <ContactForm />
        </Container>
    </>);
};

export default ContactPage;