import Title from '../UI/Title/Title';
import styles from './Footer.module.css';
import { FaFacebookF, FaInstagram, FaYoutube } from 'react-icons/fa';
import { BsTwitterX } from "react-icons/bs";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSocialData } from '../../Store/Slices/SocialSlice';

import useFetch from '../../hooks/use-fetch';

function ConditionalLink({ to, condition, className, icon }) {
    let toRender;
    if (condition) {
        toRender = <a href={to} target="_blank" rel='noreferrer' className={className}>{icon}</a>;
    }
    return toRender;
}

const AboutCompanySection = () => {

    const { data: aboutData, error, isLoading, sendRequest } = useFetch();


    const dispatch = useDispatch();
    const { facebook, instagram, x, youtube, } = useSelector(state => state.Social);
    const data = facebook || instagram || x || youtube;


    useEffect(() => {
        dispatch(getSocialData());
    }, [dispatch]);

    useEffect(() => {
        sendRequest(`*[_type == "homePage"]{
        footerDescription,title
        }| order(_createdAt desc)
        `);
    }, [sendRequest]);

    return (
        <div className={`${styles['bf-sec']} ${styles['bf-about']}`}>
            <Title className={styles.title}>About Company</Title>
            <p>
                {!isLoading && aboutData.length > 0 && aboutData[0].footerDescription}
            </p>
            {data && <ul>
                {Object.entries({ facebook, instagram, x, youtube }).map(([key, value]) => (
                    <li key={key}>
                        <ConditionalLink
                            to={value}
                            condition={value}
                            className={styles[key]}
                            icon={
                                key === "facebook" ? <FaFacebookF /> :
                                    key === "instagram" ? <FaInstagram /> :
                                        key === "x" ? <BsTwitterX /> :
                                            key === "youtube" ? <FaYoutube /> : null
                            }
                        />
                    </li>
                ))}
            </ul>}
        </div>
    );
};

export default AboutCompanySection;
