import imageUrlBuilder from '@sanity/image-url';

const ImageContainer = ({ value }) => {

    const urlBuilder = imageUrlBuilder({
        projectId: '8xmbrpb7',
        dataset: 'prod',
        useCdn: true // use the CDN for faster queries

    });

    return (
        <img
            src={urlBuilder.image(value).url()}
            alt={value.alt || " "}
        />
    );
};

export default ImageContainer;