import { configureStore } from "@reduxjs/toolkit";

import FooterSlice from "./Slices/FooterSlice";
import HomeSlice from "./Slices/HomeSlice";
import SocialSlice from "./Slices/SocialSlice";
import DataSlice from "./Slices/DataSlice";



const store = configureStore({
    reducer: {
        Footer: FooterSlice,
        Home: HomeSlice,
        Social: SocialSlice,
        WebSite: DataSlice,
    },
});

export default store;
