import { createSlice } from "@reduxjs/toolkit";
import { createClient } from '@sanity/client';

const client = createClient({
    projectId: '8xmbrpb7',
    dataset: 'prod',
    apiVersion: '2022-03-07',
    useCdn: true // use the CDN for faster queries
});


const SocialSlice = createSlice({
    name: "Social",
    initialState: {
        facebook: "",
        instagram: "",
        x: "",
        youtube: "",
        mobileNumber: "",
        email: "",
        address: "",
    },
    reducers: {
        fetchSocialData(state, action) {
            state.facebook = action.payload[0].facebook;
            state.instagram = action.payload[0].instagram;
            state.x = action.payload[0].x;
            state.youtube = action.payload[0].youtube;
            state.mobileNumber = action.payload[0].mobileNumber;
            state.email = action.payload[0].email;
            state.address = action.payload[0].address;
        }
    }
});

export const getSocialData = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            return client.fetch(`*[_type == "socialMedia"]{
      facebook,
      instagram,
      x,
      youtube,
      mobileNumber,
      email,
      address,
    }|order(_createdAt desc)
        `).then(res => {
                return res;
            });
        };
        try {
            const data = await fetchData();
            dispatch(SocialAction.fetchSocialData(data));
        } catch (error) {
            console.log(error);
        }
    };
};


export const SocialAction = SocialSlice.actions;

export default SocialSlice.reducer;