import { Link } from 'react-router-dom';
import Title from '../UI/Title/Title';
import { FaArrowRight } from 'react-icons/fa';

import styles from './Footer.module.css';

const UsefulLinksSection = () => {
  return (
    <div className={`${styles['bf-sec']} ${styles['bf-links']}`}>
      <Title className={styles.title}>Useful Links</Title>
      <ul>
        <li>
          <FaArrowRight />
          <Link to="/about">about us</Link>
        </li>
        <li>
          <FaArrowRight />
          <Link to="/gallery">our gallery</Link>
        </li>
        <li>
          <FaArrowRight />
          <Link to="/services">our services</Link>
        </li>
        <li>
          <FaArrowRight />
          <Link to="/team">our team</Link>
        </li>
        <li>
          <FaArrowRight />
          <Link to="/contact">contact us</Link>
        </li>
        {/* Add more list items as needed */}
      </ul>
    </div>
  );
};

export default UsefulLinksSection;
