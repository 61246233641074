import { createSlice } from "@reduxjs/toolkit";
import { createClient } from '@sanity/client';

const client = createClient({
    projectId: '8xmbrpb7',
    dataset: 'prod',
    apiVersion: '2022-03-07',
    useCdn: true // use the CDN for faster queries
});


const FooterSlice = createSlice({
    name: "Footer",
    initialState: {
        about: ""
    },
    reducers: {
        fetchFooterData(state, action) {
            state.about = action.payload[0].footerDescription;
        }
    }
});

export const getFooterData = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            return client.fetch(`*[_type == "homePage"]{
        footerDescription,
        }| order(_createdAt desc)
        `).then(res => {
                return res;
            });
        };
        try {
            const data = await fetchData();
            dispatch(footerAction.fetchFooterData(data));
        } catch (error) {
            console.log(error);
        }
    };
};


export const footerAction = FooterSlice.actions;

export default FooterSlice.reducer;