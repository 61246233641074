
import styles from './SquareImage.module.css'; 
import { Link } from 'react-router-dom';

const SquareImage = ({ imageSrc, index,className }) => {
  return (
    <div className={`${styles.sq} ${styles['feat-img']} ${className}`}>
      <img src={imageSrc} alt={`Post ${index}`} />
      <div className={styles['feat-ov']}>
        <Link>Contact us</Link>
      </div>
    </div>
  );
};

export default SquareImage;
