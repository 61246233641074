
import React from 'react';
import styles from './NotFound.module.css';

const NotFound = () => {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <h1 className={styles.heading}>404 - Not Found</h1>
                <p className={styles.message}>The page you are looking for might be under construction or does not exist.</p>
            </div>
        </div>
    );
};

export default NotFound;
