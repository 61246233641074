

import Title from '../../UI/Title/Title';
import Container from '../../UI/Container/Container';
import styles from './AboutSec.module.css';


const AboutSec = ({ image, content }) => {



  return (
    <section className={styles['aboutus']}>
      <Title>About Us</Title>
      <Container className={styles.aboutCont}>
        <div className={styles['ab-r']}>
          <img src={image} alt="Post" />
        </div>
        <div className={styles['ab-l']}>
          <p>
            {content}
          </p>
        </div>
      </Container>
    </section>
  );
};

export default AboutSec;
