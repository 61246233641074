import { useNavigate } from 'react-router';
import styles from './Tags.module.css';



const Tags = ({ tags }) => {
    const navigate = useNavigate();

    const handleTag = (tag) => {
        navigate(`/search?tag=${tag.value}`);
    };

    return (<div>
        <p className={styles.tagsTitle}>Tags and Materials:</p>
        <ul className={styles.TagsContainer}>
            {tags && tags.map((tag, idx) =>
                < li key={idx} > <button onClick={() => { handleTag(tags[idx]); }}>{tag.value}</button> </li>)
            }
        </ul >
    </div >);
};

export default Tags;