import { NavLink } from "react-router-dom";
import classes from "./Nav.module.css";


const NavigationLink = ({ to, children, onClick }) => {

  return <NavLink to={to} className={({ isActive, isPending }) =>
    isPending ? "pending" : isActive ? classes.active : ""
  } onClick={onClick}>
    {children}
  </NavLink>;
};


export default NavigationLink;