import AboutSec from "../HomePage/AboutSection/AboutSec";
import Blogs from "../HomePage/Blogs/Blogs";
import Features from "../HomePage/Features/Features";
import Hero from "../UI/Hero/Hero";
import ProjectShowcase from "../HomePage/Projects/Projects";
import TeamSection from "../HomePage/Team/Team";
import Clients from "../HomePage/Clients/Clients";


import { useEffect } from 'react';

import { useDispatch, useSelector } from "react-redux";
import { getHomeData } from "../../Store/Slices/HomeSlice";
const HomePage = () => {

    const { title,
        coverImg,
        description,
        aboutDescription,
        aboutImage, data } = useSelector(state => state.Home);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!data) dispatch(getHomeData());
    }, [data, dispatch]);

    return (<>
        <Hero title={title} coverImg={coverImg} intro={description} link={true} />
        <AboutSec image={aboutImage} content={aboutDescription} />
        <Features />
        <ProjectShowcase />
        <Blogs />
        <TeamSection />
        <Clients />

    </>);
};

export default HomePage;

