import ImageContainer from '../ImageContainer/ImageContainer';
import { Link } from 'react-router-dom';

import styles from './SearchResult.module.css';

const handlePostContent = (post) => {
    let content = "";
    for (let block in post) {
        if (post[block]._type === 'block') {
            for (let cont in post[block].children) {
                content += `${post[block].children[cont].text} `;
            }
        }
    }
    return content.substring(0, 150) + "...";
};

const SearchResult = (post) => {
    return (
        <li key={post._id} className={styles.result}>
            <div className={styles.resultImg}>
                <ImageContainer value={post.cover} />
            </div>
            <div className={styles.resultData}>
                <h3>{post.title}</h3>
                <p>{handlePostContent(post.content)}</p>
                {post._type === 'newPost' && (<Link to={`/posts/${post.slug.current}`}>Read more</Link>)}
                {post._type === 'project' && (<Link to={`/projects/${post.slug.current}`}>Read more</Link>)}
            </div>
        </li>
    );
};

export default SearchResult;