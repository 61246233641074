import Container from '../../UI/Container/Container';
import SquareImage from './SquareImage';
import SquareWithContent from './SquareWithContent';
import styles from './Features.module.css';

import postImage from '../../../assets/Images/post.jpg';

const squaresData = [
  { type: 'image', imageSrc: postImage },
  { type: 'content', title: 'our stage one', content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A odit, non voluptate culpa quia dolorem quaerat, vero deserunt mollitia deleniti saepe nesciunt perspiciatis nobis doloremque. Optio error ex aliquam deleniti!' },
  { type: 'image', imageSrc: postImage },
  { type: 'content', title: 'our stage one', content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A odit, non voluptate culpa quia dolorem quaerat, vero deserunt mollitia deleniti saepe nesciunt perspiciatis nobis doloremque. Optio error ex aliquam deleniti!' },
  { type: 'image', imageSrc: postImage },
  { type: 'content', title: 'our stage one', content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A odit, non voluptate culpa quia dolorem quaerat, vero deserunt mollitia deleniti saepe nesciunt perspiciatis nobis doloremque. Optio error ex aliquam deleniti!' },
  { type: 'image', imageSrc: postImage },
  { type: 'content', title: 'our stage one', content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A odit, non voluptate culpa quia dolorem quaerat, vero deserunt mollitia deleniti saepe nesciunt perspiciatis nobis doloremque. Optio error ex aliquam deleniti!' },
  { type: 'image', imageSrc: postImage },

];

const Features = () => {
  return (
    <section className={styles.feats}>
      <Container className={styles.featsCont}>
        {squaresData.map((square, index) => (
          square.type === 'image' ? (
            <SquareImage key={index} imageSrc={square.imageSrc} className={styles.feat} index={index} />
          ) : (
            <SquareWithContent key={index} title={square.title} content={square.content} className={styles.feat} />
          )
        ))}
      </Container>
    </section>
  );
};

export default Features;
