import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { PortableText } from "@portabletext/react";

import useFetch from '../../hooks/use-fetch';


import "react-image-gallery/styles/css/image-gallery.css";
import "../SinglePost/GalleryControls.css";
import Container from '../UI/Container/Container';

import Hero from '../UI/Hero/Hero';
import Tags from '../SinglePost/Tags/Tags';


import styles from './SinglePost.module.css';
import ImageContainer from '../UI/ImageContainer/ImageContainer';
import Gallery from '../SinglePost/Gallery/Gallery';
import SideBar from '../SinglePost/SideBar/SideBar';


const myPortableTextComponents = {
    types: {
        image: ImageContainer,
    },
};


const SinglePost = () => {
    const { slug } = useParams();
    const { data, isloading, error, sendRequest } = useFetch();

    useEffect(() => {
        sendRequest(`*[_type == "newPost" && slug.current == "${slug}"]{
            title,
            "cover":cover.asset->url,
            "content":content,
             gallery [] {
                    asset-> {
                             url
                            }
                         },
            tags[]{value},
          }|order(_createdAt desc)`);
    }, [sendRequest, slug]);

    const showCont = !isloading && !error && data.length > 0;
    let postCont;
    if (showCont) postCont = data[0];


    return (
        <>
            {showCont && <>
                <Hero coverImg={postCont.cover} title={postCont.title} centered={true} />
                <Container className={styles.postContainer}>
                    <div className={styles.postContent}>
                        <h2 className={styles.postTitle}>{postCont.title}</h2>
                        <div className={styles.postBody}>
                            <PortableText value={postCont.content} components={myPortableTextComponents} />
                        </div>
                        {postCont.gallery && postCont.gallery.length > 0 && <Gallery images={postCont.gallery} />}
                        {postCont.tags && <Tags tags={postCont.tags} />}
                    </div>
                    <SideBar />
                </Container>
            </>}
        </>
    );
};

export default SinglePost;