import { FaPaintBrush } from 'react-icons/fa';
import styles from './SquareWithContent.module.css'; 

const SquareWithContent = ({ title, content,className }) => {
  return (
    <div className={`${styles['feat-w']} ${className}`}>
      <div className={styles['feat-w-c']}>
        <FaPaintBrush />
        <span>explore the features</span>
        <h3>{title}</h3>
        <p>{content}</p>
      </div>
    </div>
  );
};

export default SquareWithContent;
