
import { Link } from "react-router-dom";
import styles from "./BlogCard.module.css";

import { motion, } from "framer-motion";


const BlogCard = ({ slug, id, title, imageSrc, linkText }) => {

  const fadeFromBottom = {
    hidden: { opacity: 0, y: 20, },
    visible: { opacity: 1, y: 0, },
  };
  return (
    <motion.div className={styles["card"]}
      variants={fadeFromBottom}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.5, delay: 0.2 }}
    >
      <img src={imageSrc} alt={`card ${id}`} />
      <div className={styles["bl-ov"]}>
        <Link to={`/posts/${slug}`} className={styles["p-name"]}>
          {title}
        </Link>
        <Link to={`/posts/${slug}`} className={styles["read-more"]}>
          {linkText}
        </Link>
      </div>
    </motion.div >
  );
};

export default BlogCard;
