import { Outlet } from "react-router-dom";
import Navbar from "../NavBar/Nav";
import Footer from "../Footer/Footer";

const HomeLayout = ({ logo, title }) => {
    return (
        <>
            <Navbar logo={logo} />
            <Outlet context={title} />
            <Footer logo={logo} />
        </>
    );
};

export default HomeLayout;