
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';

import { FaClock, FaArrowRight } from 'react-icons/fa';

const SingleArticle = ({ imageUrl, date, title, link }) => {
    return (
        <li>
            <Link to={link}>
                <img src={imageUrl} alt="Article" />
            </Link>
            <div className={styles['bf-pf']}>
                <span>
                    <FaClock />
                    {date}
                </span>
                <Link to={link} className={styles['bfpf-title']}>
                    {title}
                </Link>
                <Link to={link}>
                    Read more <FaArrowRight />
                </Link>
            </div>
        </li>
    );
};

export default SingleArticle;
