import React from 'react';
import styles from './Projects.module.css';
import { FaMapMarker } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Title from '../../UI/Title/Title';

import img1 from '../../../assets/Images/p1.jpg';
import img2 from '../../../assets/Images/p2.jpg';
import img3 from '../../../assets/Images/p3.jpg';

const projects = [
  { id: 1, imageSrc: img3, name: 'Project 1', location: 'ksa' },
  { id: 2, imageSrc: img1, name: 'Project 2', location: 'ksa' },
  { id: 3, imageSrc: img2, name: 'Project 3', location: 'ksa' },
  { id: 4, imageSrc: img1, name: 'Project 4', location: 'ksa' },
  { id: 5, imageSrc: img2, name: 'Project 5', location: 'ksa' },
  { id: 6, imageSrc: img3, name: 'Project 6', location: 'ksa' },
];
const ProjectShowcase = () => {

  return (
    <>
      <section className={styles['projects-show']}>
        <Title>Projects</Title>
        <div className={styles.pjs}>
          {projects.map((project) => (
            <div key={project.id} className={`${styles.project}`}>
              <img src={project.imageSrc} alt={`Project ${project.id}`} />
              <div className={styles['pj-detail']}>
                <div className={styles['pd-i']}>
                  <Link href="#" className={styles['p-name']}>{project.name}</Link>
                  <span><FaMapMarker />{project.location}</span>
                  <Link href="#">more info</Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section></>
  );
};

export default ProjectShowcase;
