import { createSlice } from "@reduxjs/toolkit";
import { createClient } from '@sanity/client';

const client = createClient({
    projectId: '8xmbrpb7',
    dataset: 'prod',
    apiVersion: '2022-03-07',
    useCdn: true // use the CDN for faster queries
});


const HomeSlice = createSlice({
    name: "Home",
    initialState: {
        title: "",
        coverImg: "",
        description: "",
        aboutDescription: "",
        aboutImage: "",
        data: false,
        isLoading: true,
        error: null
    },
    reducers: {
        fetchHomeData(state, action) {
            if (Array.isArray(action.payload)) {
                state.title = action.payload[0].title;
                state.coverImg = action.payload[0].heroImage;
                state.description = action.payload[0].description;
                state.aboutDescription = action.payload[0].aboutDescription;
                state.aboutImage = action.payload[0].aboutImage;
                state.isLoading = action.payload.length > 0 ? false : true;
                state.error = null;
                state.data = true;
            } else {
                state.isLoading = false;
                state.error = action.payload;
            }
        }
    }
});

export const getHomeData = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            return client.fetch(`*[_type == "homePage"]{
            title,
            "heroImage":heroImage.asset->url,
            description,
            aboutDescription,
            "aboutImage":aboutImage.asset->url
        }| order(_createdAt desc)
        `).then(res => {
                return res;
            });
        };
        try {
            const data = await fetchData();
            dispatch(HomeAction.fetchHomeData(data));
        } catch (error) {
            dispatch(HomeAction.fetchHomeData(error.message));
        }
    };
};


export const HomeAction = HomeSlice.actions;

export default HomeSlice.reducer;