import { Link } from 'react-router-dom';
import Container from '../UI/Container/Container';
import classes from './Nav.module.css';

import NavigationLink from './NavigationLink';
import { useEffect, useRef, useState } from 'react';

import { AnimatePresence, motion, useMotionValueEvent, useScroll } from 'framer-motion';
import SearchBar from './SearchBar';


const Navbar = ({ logo }) => {

    const [menuOpen, setMenuOpen] = useState(false);

    const openMenuHandler = () => {
        setMenuOpen(!menuOpen);
    };

    const [showSearch, setShowSearch] = useState(false);

    const showSearchHandler = () => {
        setShowSearch(!showSearch);
    };


    const menuVars = {
        initial: {
            x: "100%"
        },
        animate: {
            x: 0,
        },
        exit: {
            x: "100%"
        }
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    window.addEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
    });

    useEffect(() => {
        if (windowWidth > 768) {
            setMenuOpen(false);
        }
    }, [windowWidth]);

    const [hidden, setHidden] = useState(false);
    const { scrollY } = useScroll();

    useMotionValueEvent(scrollY, "change", (latest) => {
        const previous = scrollY.getPrevious();
        if (latest > previous && latest > 100) {
            setHidden(true);
        } else {
            setHidden(false);
        }
    });

    const menuRef = useRef(null);

    const handleDocumentClick = (event) => {
        if (!menuRef.current.contains(event.target)) {
            setMenuOpen(false);
        }
    };

    useEffect(() => {
        document.body.style.overflow = menuOpen ? "hidden" : "auto";
        if (menuOpen) {
            document.addEventListener("click", handleDocumentClick);
            document.addEventListener("touchend", handleDocumentClick);
        } else {
            document.removeEventListener("click", handleDocumentClick);
            document.removeEventListener("touchend", handleDocumentClick);
        }
    }, [menuOpen]);

    const handleMenuClick = () => {
        setMenuOpen(false);
    };

    return (
        <motion.header className={classes.navbar} ref={menuRef}
            variants={
                {
                    hidden: {
                        y: "-130%",
                        opacity: 0
                    },
                    visible: {
                        y: 0,
                        opacity: 1
                    }
                }
            }
            animate={hidden ? "hidden" : "visible"}
            transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.5 }}
        >
            <Container className={classes.navCont}>
                <div className={classes["nav-r"]}>
                    <Link to="/">
                        <img src={logo} alt="Logo" />
                    </Link>
                </div>
                <div className={classes["nav-l"]}>
                    <nav>
                        <AnimatePresence>
                            {menuOpen || windowWidth > 768 ?
                                <motion.ul
                                    variants={menuVars}
                                    initial="initial"
                                    animate="animate"
                                    exit="exit"
                                    transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.5 }}
                                    className={`${showSearch ? classes["srch-vis"] : ""}`}>
                                    <li>
                                        <NavigationLink onClick={handleMenuClick} to="/">Home</NavigationLink>
                                    </li>
                                    <li>
                                        <NavigationLink onClick={handleMenuClick} to="/about">About us</NavigationLink>
                                    </li>
                                    <li>
                                        <NavigationLink onClick={handleMenuClick} to="/investors">Investors</NavigationLink>
                                    </li>
                                    <li>
                                        <NavigationLink onClick={handleMenuClick} to="/construction">Construction solutions</NavigationLink>
                                    </li>
                                    <li>
                                        <NavigationLink onClick={handleMenuClick} to="/industry">Industry solutions</NavigationLink>
                                    </li>
                                    <li>
                                        <NavigationLink onClick={handleMenuClick} to="/contact">Contact us</NavigationLink>
                                    </li>
                                </motion.ul> : null}
                        </AnimatePresence>
                    </nav>
                    <SearchBar showSearch={showSearch} onClick={showSearchHandler} />
                    {windowWidth <= 768 && <button className={`${classes["nav-btn"]} ${menuOpen ? classes.active : ""}`} onClick={openMenuHandler}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>}
                </div >
            </Container >
        </motion.header >

    );
};



export default Navbar;
