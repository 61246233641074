
const MapComponent = () => {
    return (
        <div className="mapouter">
            <div className="gmap_canvas">
                <iframe
                    title="Google Map"
                    src="https://maps.google.com/maps?q=alexandria&t=&z=13&ie=UTF8&iwloc=&output=embed"
                    frameBorder="0"
                    scrolling="no"
                    style={{ width: '100%', height: '400px' }}
                ></iframe>
                <style>
                    {`.mapouter{position:relative;height:400px;width:100%;background:#fff;} 
            .maprouter a{color:#fff !important;position:absolute !important;top:0 !important;z-index:0 !important;}`}
                </style>
                <a href="https://blooketjoin.org">blooket join</a>
                <style>
                    {`.gmap_canvas{overflow:hidden;height:400px;width:100%}
            .gmap_canvas iframe{position:relative;z-index:2}`}
                </style>
            </div>
        </div>
    );
};

export default MapComponent;
