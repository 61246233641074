import { useState, useRef, useEffect } from 'react';

import styles from './Gallery.module.css';
import { useCallback } from 'react';

import { GrNext, GrPrevious } from "react-icons/gr";

const Gallery = ({ images }) => {

    if (images.length !== 1) images = [...images, images[0]];

    const sliderRef = useRef(null);
    const thumbsRef = useRef(null);
    const [currentImage, setCurrentImage] = useState(0);

    const setScroll = useCallback((indx) => {
        const scrollWidth = thumbsRef.current.scrollWidth;
        const pixelValue = (indx) * 100 / (images.length - 2) * scrollWidth / 100;
        thumbsRef.current.scrollTo({ left: pixelValue });
    }, [images.length]);

    const nextImage = useCallback(() => {
        if (currentImage === images.length - 2) {
            setCurrentImage((currentImage + 1) % images.length);
            setTimeout(() => {
                sliderRef.current.style.transition = "none";
                setCurrentImage(0);
                setTimeout(() => {
                    sliderRef.current.style.transition = "transform 0.5s ease-in-out";
                }, 50);
            }, 500);
            setScroll(0);
            return;
        }
        setCurrentImage((currentImage + 1) % images.length);
        setScroll((currentImage + 1) % images.length);
    }, [currentImage, images.length, setScroll]);

    const prevImage = () => {
        if (currentImage === 0) {
            sliderRef.current.style.transition = "none";
            setCurrentImage(images.length - 1);
            setTimeout(() => {
                sliderRef.current.style.transition = "transform 0.5s ease-in-out";
                setCurrentImage(images.length - 2);
            }, 50);
            setScroll(images.length - 2);
            return;
        }
        setCurrentImage(currentImage - 1);
        setScroll(currentImage - 1);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            images.length > 1 && nextImage();
        }, 3000);

        return () => clearInterval(interval);
    }, [nextImage, images]);

    const [width, setWidth] = useState(500);

    useEffect(() => {
        images.length > 1 && setWidth(sliderRef.current.parentElement.clientWidth);
    }, [images]);


    const translate = `translateX(-${currentImage * 100 / (images.length)}%)`;

    const selectSlide = (e) => {
        const idx = e.target.dataset.index;
        setCurrentImage(+idx);

    };

    let content = null;

    if (images.length === 1) {
        content = <div className={styles["post-gallery__single-image"]}>
            <img src={images[0].asset.url} alt="gallery" />
        </div>;
    } else {
        content = <>
            <div className={styles["post-gallery"]} style={{ "--size": `${width}px` }} >
                <div className={styles["post-gallery__controls"]}>
                    <button onClick={prevImage}><GrPrevious /></button>
                    <button onClick={nextImage}><GrNext /></button>
                </div>
                <div className={styles["post-gallery__wrapper"]} >
                    <div className={styles["post-gallery__inner"]} style={{ "transform": translate }} ref={sliderRef}>
                        {images.map((image, idx) => {
                            return <div className={styles["post-gallery__image"]} key={idx}>
                                <img src={image.asset.url} alt="gallery" />
                            </div>;
                        })}
                    </div>
                </div>
                <div className={styles["post-gallery__thumbs"]} ref={thumbsRef}>
                    <div className={styles["post-gallery__thumbs-inner"]}>
                        {[...images].map((image, idx) => {
                            return idx !== images.length - 1 &&
                                <div className={`${currentImage === idx && styles["active-slide"]}`} key={idx} onClick={selectSlide} data-index={idx}>
                                    <img src={image.asset.url} alt="gallery" />
                                </div>;
                        })}
                    </div>
                </div>
            </div></>;
    }

    return (
        <section>
            <h2 className={styles["post-gallery__title"]}>Gallery</h2>
            {content}
        </section>
    );
};

export default Gallery;