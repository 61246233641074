import { useEffect } from 'react';

import Scroller from '../Scroller/Scroller';
import Container from '../../UI/Container/Container';

import useFetch from '../../../hooks/use-fetch';

import styles from './clients.module.css';
import Client from './Client';


const Clients = () => {
    const { data: logos, error, isloading, sendRequest } = useFetch();

    useEffect(() => {
        sendRequest(`*[_type == "clients"]{
      altText,
      "imageSrc":logo.asset->url,
      _id
    }| order(_createdAt desc)`);
    }, [sendRequest]);

    const showCont = !isloading && !error && logos.length > 0;
    let content = null;
    if (showCont) {
        content = logos.map((logo) => (
            <Client key={logo._id} {...logo} />
        ));
    }

    return (
        <section className={styles['clients']}>
            <Container>
                {content && (
                    <Scroller className={styles.scrollParent}>
                        {content}
                    </Scroller>
                )}
            </Container>
        </section>
    );
};


export default Clients;
