import { Link } from "react-router-dom";
import Container from "../../UI/Container/Container";
import Title from "../../UI/Title/Title";
import BlogCard from "../../UI/BlogCard/BlogCard";
import styles from "./Blogs.module.css";

import useFetch from "../../../hooks/use-fetch";
import { useEffect } from "react";

const Blogs = () => {
  const { data: blogs, error, isloading, sendRequest } = useFetch();

  useEffect(() => {
    sendRequest(`*[_type == "newPost"]{
    title,
     "cover":cover.asset->url,
    "slug":slug.current,
    _id
  }|order(_createdAt desc) [0..5]`);
  }, [sendRequest]);


  let content = <p>Loading...</p>;

  if (!isloading && blogs.length > 0 && !error) {
    content = <>
      <div className={styles["blogs-container"]}>
        {blogs.map((blog) => (
          <BlogCard
            key={blog._id}
            id={blog.slug}
            title={blog.title}
            imageSrc={blog.cover}
            linkText="Read More"
            slug={blog.slug}
          />
        ))}
      </div>
      <div className={styles["st-cont"]}>
        <Link to="/blogs" className={styles["center-btn"]}>
          more blogs
        </Link>
      </div></>;

  }

  if (error) {
    content = <p>Can't Retrieve Blogs at this time</p>;
  }

  if (blogs.length === 0 && !isloading && !error) {
    content = <p>No Blogs Found</p>;
  }


  return (
    <section className={styles["blogs"]}>
      <Title> our blog</Title>
      <Container className={styles.blogsCont}>
        {content}
      </Container>
    </section>
  );
};

export default Blogs;
