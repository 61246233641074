import { motion, useMotionValueEvent, useScroll } from 'framer-motion';

import classes from "./SideBar.module.css";
import { useState } from 'react';

const SideBar = (props) => {

  const [scrolling, setScrolling] = useState(false);

  const { scrollY } = useScroll();

  useMotionValueEvent(scrollY, "change", (latest) => {
    const previous = scrollY.getPrevious();
    if (latest > previous && latest > 100) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  });

  return <motion.aside className={classes.sidebar}
    variants={
      {
        scrolling: {
          y: 0,
        },
        visible: {
          y: "90px",

        }
      }
    }
    animate={scrolling ? "scrolling" : "visible"}
    transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.5 }}>{props.children}</motion.aside>;
};

export default SideBar;
