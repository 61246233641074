import "./App.css";

import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";

import MainLayout from "./components/Routes/MainLayout";
import HomePage from "./components/Routes/HomePage";
import ContactPage from "./components/Routes/ContactPage";
import NotFound from "./components/Routes/NotFound.";
import Search from "./components/Routes/Search";

import ScrollToTop from "./components/Routes/ScrollToTop";
import { useEffect } from "react";
import SinglePost from "./components/Routes/SinglePost";
import { useDispatch, useSelector } from "react-redux";
import { getWebSiteData } from "./Store/Slices/DataSlice";



function App() {

  const { title, description, logo, favIcon } = useSelector(state => state.WebSite);

  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(getWebSiteData());
  }, [dispatch]);

  const dataLoaded = title && description && logo && favIcon;

  useEffect(() => {
    if (dataLoaded) {
      document.title = title;
      const favicon = document.querySelector('[rel="shortcut icon"]');
      favicon.href = favIcon;
      const webSiteDescription = document.querySelector('meta[name="description"]');
      webSiteDescription.content = description;
    }
  }, [dataLoaded, title, description, favIcon]);


  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<MainLayout logo={logo} />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/posts/:slug" element={<SinglePost />} />
          <Route path="/search" element={<Search />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>

    </BrowserRouter>
  );
}

export default App;
