import React, { useEffect, useRef, useState } from "react";
import styles from "./Scroller.module.css";

const Scroller = ({ children, className }) => {
  const scrollerRef = useRef(null);
  const [animated, setAnimated] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      const scrollerInner = scrollerRef.current;
      scrollerInner.querySelectorAll('.duplicated').forEach((item) => item.remove());
      scrollerInner.style.width = "max-content";
      const scrollerContent = Array.from(scrollerInner.children);
      let windowWidth = window.innerWidth;
      let scrollerInnerWidth = scrollerInner.getBoundingClientRect().width * 2;
      if (scrollerInnerWidth < windowWidth * 2) {
        scrollerInner.querySelectorAll('.duplicated').forEach((item) => item.remove());
        setAnimated(false);
        scrollerInner.style.width = "100%";
      } else {
        scrollerContent.forEach(item => {
          const duplicatedItem = item.cloneNode(true);
          duplicatedItem.classList.add('duplicated');
          scrollerInner.appendChild(duplicatedItem);
        });

        setAnimated(true);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={styles["scroller"]}>
      <div
        className={`${styles["scroller_inner"]} ${animated ? styles["animated"] : ""} ${className} `}
        ref={scrollerRef}
        style={{ "--duplicates": 2 }}
      >
        {children}
      </div>
    </div >
  );
};

export default Scroller;
