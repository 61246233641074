

import styles from './Footer.module.css';

import AboutCompanySection from './AboutCompanySection';
import UsefulLinksSection from './UsefulLinksSection';
import ContactSection from './ContactSection';
import ArticlesSection from './ArticlesSection';
import Container from '../UI/Container/Container';


const Footer = ({ logo }) => {



    return (
        <footer className={styles['footer']}>
            <Container>
                <div className={styles['top-footer']}>
                    <a href="/">
                        <img src={logo} alt="Footer Logo" />
                    </a>
                    <div className={styles['subs']}>
                        <h4>subscribe to our newsletter </h4>
                        <form className={styles.mail}>
                            <input type="email" className={styles['inp-email']} />
                            <button className={styles['inp-btn']} type="button">Subscribe</button>
                        </form>
                    </div>
                </div>
                <div className={styles['bot-footer']}>
                    <AboutCompanySection />
                    <UsefulLinksSection />
                    <ContactSection />
                    <ArticlesSection />
                </div>
            </Container>
        </footer>
    );
};

export default Footer;;
