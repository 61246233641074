import { useState, useCallback } from "react";
import { createClient } from '@sanity/client';

const client = createClient({
    projectId: '8xmbrpb7',
    dataset: 'prod',
    apiVersion:'2022-03-07',
    useCdn: true // use the CDN for faster queries
});

const useFetch = () => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [isloading,setIsLoading]=useState(true);
    
    const sendRequest= useCallback( (query) => {
        setIsLoading(true);
        setError(null);
            client.fetch(query)
                .then(resp => {
                    setData(resp);
                })
                .catch(error => {
                    setError(error.message || 'Something went wrong!');
                });
        setIsLoading(false);
    }, []);



    return { data, error, isloading, sendRequest };
}

export default useFetch;