import styles from './Title.module.css';

const Title = ({ children, className, white }) => {
  return (
    <div className={`${styles['st-cont']} ${className}`} style={white && { color: "white" }}>
      <h2 className={styles['sec-title']}>{children}</h2>
    </div>
  );
};

export default Title;